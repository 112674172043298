import { useQuery } from "@apollo/client";
import { gql } from "_graphql-types-frontend";
import { Tabs } from "antd";
import { GetBusinessApplicantDocumentsQuery } from "_graphql-types-frontend/graphql";
import UploadBusinessApplicationDocument from "./UploadBusinessApplicationDocument.component";
import UploadedBusinessApplicationDocuments from "./UploadedBusinessApplicationDocuments";

const businessDocuments = {
  ["Business"]: [
    { id: 22, description: "Business Plan" },
    { id: 78, description: "List of company’s outstanding SOWs" },
    { id: 79, description: "Employee organizational chart" }, // Organization Chart
    {
      id: 80,
      description: "List of all equity owners and their ownership percentage",
    },
    { id: 81, description: "Resumes/bios for key principals" },
    {
      id: 82,
      description:
        "Executed agreements/documents related to future company opportunities",
    },
    {
      id: 94,
      description: "Other",
    },
  ],
  ["Financial"]: [
    {
      id: 83,
      description:
        "Interim financial statements (w/ year over year comparison) for most recent available period",
    },
    {
      id: 84,
      description:
        "Accounts receivable aging as of most recent available period",
    },
    {
      id: 85,
      description: "Accounts payable aging as of most recent available period ",
    },
    {
      id: 86,
      description:
        "Detailed breakdown of company's short-term/long-term assets",
    },
    { id: 87, description: "Owner’s personal financial statement" },
    { id: 88, description: "Financial projections " },
  ],
  ["Tax Returns"]: [
    { id: 89, description: "3 years of tax returns for the company" },
    {
      id: 90,
      description: "3 years of personal tax returns with K-1s for the owner ",
    },
  ],
  ["Debt"]: [
    {
      id: 91,
      description: "Loan agreements for all outstanding debt facilities ",
    },
    { id: 92, description: "Current debt schedule" },
  ],
};

export const BUSINESS_APPLICATION_DOCUMENTS = gql(`
  query getBusinessApplicantDocuments($companyId: Int!) {
    documents(
      filter: { companyId: $companyId }
      page: { limit: 100, offset: 0 }
    ) {
      items {
        id
        name
        date
        signedUrl
        signedThumbnailUrl
        documentTypeEnumId
        accessLevel
        isGallery
        companyId
        documentTypeEnum {
          id
          name
        }
        businessObjectEnumId
        businessObject {
          id
          name
        }
      }
      total
    }
    documentTypeEnumList {
      items {
        name
        id
      }
    }
  }
`);

const { TabPane } = Tabs;

function BusinessApplicationDocuments({ companyId }: { companyId: number }) {
  const { data, error, loading } = useQuery<GetBusinessApplicantDocumentsQuery>(
    BUSINESS_APPLICATION_DOCUMENTS,
    { variables: { companyId } }
  );

  if (error) return <>Error fetching files</>;
  if (!data) return null;

  return (
    <>
      <div style={{ marginTop: "15px" }}>Please complete all sections:</div>
      <Tabs>
        {Object.entries(businessDocuments).map(([key, value]) => {
          return (
            <TabPane tab={key} key={key}>
              <UploadBusinessApplicationDocument
                availableDocumentTypes={value}
                companyId={companyId}
              />
              <UploadedBusinessApplicationDocuments
                companyId={companyId}
                documents={data.documents.items}
                documentTypes={value}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </>
  );
}

export default BusinessApplicationDocuments;
