import { Button, Form, Upload, DatePicker, Select } from "antd";
import { notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { gql } from "_graphql-types-frontend";
import { BUSINESS_APPLICATION_DOCUMENTS } from "./BusinessApplicationDocuments.component";
const { Option } = Select;

type UploadBusinessApplicationDocumentProps = {
  availableDocumentTypes: { id: number; description: string }[];
  companyId: number;
};

const SAVE_DOCUMENT = gql(`
  mutation uploadBussinessApplicantDocument($input: DocumentInput!) {
    addDocument(input: $input) {
      id
    }
  }
`);

const PRIVATE_DOCUMENT_ACCESS = 3;

const COMPANY_DOCUMENT_OBJECT = 3;

function UploadBusinessApplicationDocument({
  availableDocumentTypes,
  companyId,
}: UploadBusinessApplicationDocumentProps) {
  const [form] = Form.useForm();

  const [mutateFunction, { loading }] = useMutation(SAVE_DOCUMENT, {
    refetchQueries: [
      { query: BUSINESS_APPLICATION_DOCUMENTS, variables: { companyId } }, // DocumentNode object parsed with gql
      "getBusinessApplicantDocuments", // Query name
    ],
    onCompleted: () => notification.open({ message: "File Uploaded" }),
  });

  const saveDocument = (values: any) => {
    mutateFunction({
      variables: {
        input: {
          file: values.file.file.originFileObj,
          date: values.date.format("YYYY-MM-DD"),
          companyId,
          documentTypeEnumId: Number(values.documentType),
          accessLevel: PRIVATE_DOCUMENT_ACCESS,
          businessObjectEnumId: COMPANY_DOCUMENT_OBJECT,
          allowDuplicateFile: true,
        },
      },
    }).then(() => {
      form.resetFields();
    });
  };

  return (
    <Form form={form} layout="vertical" onFinish={saveDocument}>
      <Form.Item
        label="Document"
        name="file"
        rules={[{ required: true, message: "Please select a file" }]}
      >
        <Upload
          customRequest={({ onSuccess }: any) => {
            onSuccess("ok");
          }}
        >
          <Button>
            Upload <UploadOutlined />
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label="Document Type"
        name="documentType"
        rules={[{ required: true, message: "Please select a document Type" }]}
      >
        <Select style={{ width: "300px" }}>
          {availableDocumentTypes.map(documentType => (
            <Option key={documentType.id}>{documentType.description}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Date"
        name="date"
        rules={[
          {
            required: true,
            message: "Please select the date corresponding to the document",
          },
        ]}
      >
        <DatePicker style={{ width: "300px" }} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default UploadBusinessApplicationDocument;
