import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import { useMutation } from "@apollo/client";
import { gql } from "_graphql-types-frontend";
import { GetBusinessApplicantDocumentsQuery } from "_graphql-types-frontend/graphql";
import { notification, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useMemo } from "react";
import { BUSINESS_APPLICATION_DOCUMENTS } from "./BusinessApplicationDocuments.component";

type Props = {
  companyId: number;
  documents: GetBusinessApplicantDocumentsQuery["documents"]["items"];
  documentTypes: { id: number; description: string }[];
};

const DELETE_DOCUMENT = gql(`
  mutation deleteBusinessApplicationDocument($id: Int!) {
    deleteDocument(id: $id)
  }
`);

const columns: ColumnsType<{
  type: string;
  name: string;
  id: number;
  date: any;
}> = [
  { title: "Type", dataIndex: "type", key: "type" },
  { title: "Name", dataIndex: "name", key: "name" },
  { title: "Date", dataIndex: "date", key: "date" },
  { title: "", dataIndex: "download", key: "download" },
  { title: "", dataIndex: "delete", key: "delete" },
];

function UploadedBusinessApplicationDocuments({
  documents,
  documentTypes,
  companyId,
}: Props) {
  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    refetchQueries: [
      { query: BUSINESS_APPLICATION_DOCUMENTS, variables: { companyId } }, // DocumentNode object parsed with gql
      "getBusinessApplicantDocuments", // Query name
    ],
    onCompleted: () => notification.open({ message: "Document removed" }),
    onError: () => notification.open({ message: "Error Removing Document" }),
  });

  const documentStatus = useMemo(() => {
    const missingDocumentTypes = documentTypes.filter(documentType => {
      return !documents
        .map(({ documentTypeEnumId }) => documentTypeEnumId)
        .includes(documentType.id);
    });

    return {
      missingDocumentTypes,
    };
  }, [documents, documentTypes]);

  const tableData = useMemo(() => {
    return documents
      .map(({ id, name, date, documentTypeEnum, signedUrl }) => ({
        type:
          documentTypes.find(({ id }) => id === documentTypeEnum.id)
            ?.description || "",
        name: name,
        id: id,
        date: date,
        download: signedUrl && (
          <a href={signedUrl}>
            <DownloadOutlined />
          </a>
        ),
        delete: (
          <DeleteOutlined
            onClick={() => deleteDocument({ variables: { id } })}
          />
        ),
      }))
      .filter(({ type }) => !!type);
  }, [documents]);

  return (
    <>
      {documentStatus.missingDocumentTypes.length > 0 && (
        <div data-cy="missing-documents">
          <h2>Please Upload The Following Documents</h2>
          <ul>
            {documentStatus.missingDocumentTypes.map(document => {
              return (
                <li>
                  - <em>{document.description}</em>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <Table pagination={false} columns={columns} dataSource={tableData} />
    </>
  );
}

export default UploadedBusinessApplicationDocuments;
